import {
  CheckIcon,
  FiltersIcon,
  SettingsDeactivateIcon,
  SettingsExportIcon,
  SettingsIcon,
  SettingsTeamIcon,
  StateIcon,
  ContactIcon,
  TagIcon,
  FileUploadIcon,
  FrequencyIcon
} from '@papertrail/styleguide'
import React from 'react'
import SettingsItem from './SettingsItem'
import { useSessionAccount, useSessionUser } from '@papertrail/web3-session'

type Props = {
  onClose: () => void
}

export default function SettingsPanel(props: Props) {
  const { onClose } = props
  const account = useSessionAccount()
  const user = useSessionUser()

  return (
    <div>
      <SettingsItem icon={SettingsIcon} route="/settings" onClose={onClose} label="General account settings" />
      <SettingsItem icon={SettingsTeamIcon} route="/settings/team" onClose={onClose} label="Team settings" />
      <SettingsItem icon={FrequencyIcon} route="/tools/frequencies" onClose={onClose} label="Frequency manager" />
      <SettingsItem icon={StateIcon} route="/tools/state" onClose={onClose} label="State manager" />
      <SettingsItem icon={FiltersIcon} route="/tools/filters" onClose={onClose} label="Filter manager" />
      <SettingsItem icon={TagIcon} route="/tools/tags" onClose={onClose} label="Tag manager" />
      {(account && account.hasChecklistEditor) || (user && user.isPapertrailEmployee) ? (
        <SettingsItem icon={CheckIcon} route="/tools/checklist-editor" onClose={onClose} label="Checklists" />
      ) : null}
      {(account && account.hasImportTool) || (user && user.isPapertrailEmployee) ? (
        <SettingsItem icon={FileUploadIcon} route="/import-tool" onClose={onClose} label="Import Records" />
      ) : null}

      <SettingsItem icon={ContactIcon} route="/tools/contact" onClose={onClose} label="Account Contacts" />

      <SettingsItem icon={SettingsExportIcon} route="/settings/export" onClose={onClose} label="Full account export" />

      <SettingsItem
        icon={SettingsDeactivateIcon}
        route="/settings/deactivate"
        onClose={onClose}
        label="Deactivate account"
      />
    </div>
  )
}
